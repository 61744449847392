
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { NextSeo } from 'next-seo';
import Error from 'next/error';
import { useRouter } from 'next/router';
import { GetStaticPropsContext } from 'next/types';
import { Page as MakeswiftPage, MakeswiftPageSnapshot } from '@makeswift/runtime/next';
import { readFileSync } from 'fs';
import { join } from 'path';
import { getBaseUrl } from '@/lib/contentful/utils';
import '@/lib/makeswift/components';
import { generateSeoUrl } from '@/utils/seo/generateSeoUrl';
import { getPageSnapshot } from '../src/lib/makeswift/client';
async function getStaticProps({ previewData, preview, locale }: GetStaticPropsContext<{
    slug: string;
}, {
    makeswift: boolean;
}>) {
    try {
        let snapshot: MakeswiftPageSnapshot | null = null;
        if (preview) {
            snapshot = await getPageSnapshot({
                pathname: '/errors/404',
                previewData: previewData,
                locale: locale,
                timeout: {
                    ms: 10000,
                    message: `Timeout request getPageSnapshot for "/errors/404" – Locale: ${locale}`
                }
            });
        }
        else {
            const errorPageFile = readFileSync(join(process.cwd(), 'src/lib/error/404.json')).toString();
            const errorPages = JSON.parse(errorPageFile);
            snapshot = (errorPages[locale as string] || errorPages['en-US']) as MakeswiftPageSnapshot;
        }
        return {
            props: { snapshot }
        };
    }
    catch (error) {
        return { props: { snapshot: null } };
    }
}
type Props = {
    snapshot: MakeswiftPageSnapshot | null;
};
export default function Page({ snapshot }: Props) {
    const { asPath, locale } = useRouter();
    const baseUrl = getBaseUrl(locale);
    const url = new URL(asPath, baseUrl);
    if (snapshot == null) {
        return <Error statusCode={404}/>;
    }
    return (<>
			<NextSeo canonical={generateSeoUrl(url.href)} openGraph={{
            url: generateSeoUrl(url.href)
        }}/>
			<MakeswiftPage snapshot={snapshot}/>
		</>);
}

    async function __Next_Translate__getStaticProps__194f62b944d__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__194f62b944d__ as getStaticProps }
  